/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Modal, Button } from "components";
import { updateAdblockIncentives } from "api";
import { useMainContext } from "contexts";
import { validateIncentive } from "utils";

export const DeleteAdblockIncentiveModal = ({
  open,
  onClose,
  onDelete = () => {},
  text = "",
  incentiveData = [],
  data,
}) => {
  const { currentAdBlockId } = useMainContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleCancel = () => {
    onClose();
  };

  const handleDelete = async (e) => {
    setIsLoading(true);
    e.stopPropagation();
    await updateAdblockIncentives(
      currentAdBlockId,
      incentiveData.filter(
        (a) =>
          a?.index !== data.index &&
          validateIncentive(a) &&
          a?.incentive_attach_to === "Adblock"
      )
    );
    setIsLoading(false);
    onDelete();
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      size="medium"
      title={"Delete Adblock Incentive"}
    >
      <div>
        <div> Are you use your want to delete {text}?</div>
      </div>
      <div className="d-flex mt-4">
        <Button
          size="small"
          buttonTheme="dark"
          width="fit-content"
          className="ml-auto"
          type="button"
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>
        <Button
          size="small"
          buttonTheme="primary"
          width="fit-content"
          className="ml-4"
          type="button"
          onClick={(e) => handleDelete(e)}
          isLoading={isLoading}
        >
          Delete
        </Button>
      </div>
    </Modal>
  );
};
