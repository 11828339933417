import React from "react";
import styled from "styled-components";
import {
  Card,
  ScrollView,
  InfoDropDown,
  InfoInput,
  InfoCheckBox,
  IncentiveTypeSearch,
  OfferInfoContainer,
} from "components";
import { INIT_INCENTIVE_TEXT } from "config";
import { useMainContext } from "contexts";
import { INCENTIVE_TYPE_DATA, INCENTIVE_ATTACH_TO_DATA, INCENTIVE_ATTACH_TO_DATA_PAGE_ONLY } from "config";

export const IncentiveInfo = ({ isEmpty, pageOnly }) => {
  const { updateInfos, isManualEntry } = useMainContext();

  const handleIncentiveTypeChange = (params) => {
    if (params && isManualEntry) {
      setTimeout(() => {
        updateInfos({
          incentive_text: INIT_INCENTIVE_TEXT[params],
          incentive_type: params,
        });
      }, 300);
    }
  };
  return (
    <InfoSection title="INCENTIVE INFORMATION" className="flex-1 flex-column">
      {isEmpty ? (
        <div className="mt-4 pt-4 text-center">No incentives available</div>
      ) : (
        <ScrollView>
          <form autoComplete="off">
            <input style={{ display: "none" }} autoComplete="false"></input>

            <OfferInfoContainer inputId="incentive_type">
              <IncentiveTypeSearch
                inputId="incentive_type"
                label="Incentive Type"
                data={INCENTIVE_TYPE_DATA}
                disabled={!isManualEntry}
                onChange={handleIncentiveTypeChange}
              />
            </OfferInfoContainer>

            <InfoInput
              className="mt-3"
              label="Incentive Text"
              inputId="incentive_text"
              shouldMoveNextOnEnter
            />
            <div className="d-flex mt-3">
              <InfoDropDown
                label="Attached To"
                data={ !pageOnly ? INCENTIVE_ATTACH_TO_DATA : INCENTIVE_ATTACH_TO_DATA_PAGE_ONLY}
                inputId="incentive_attach_to"
              />

              <InfoInput
                label="Limit"
                inputId="incentive_limit"
                type="float"
                shouldMoveNextOnEnter
              />
            </div>

            <InfoCheckBox
              className="mt-3"
              inputId="incentive_include_net_cost"
              label="Include in Net Cost"
            />

             <InfoCheckBox
              className="mt-3"
              inputId="incentive_by_mail"
              label="By Mail"
            />

             <InfoCheckBox
              className="mt-3"
              inputId="incentive_online_incentive"
              label="Online Incentive"
            />
          </form>
        </ScrollView>
      )}
    </InfoSection>
  );
};

const InfoSection = styled(props => <Card {...props}/>)`
  height: calc(100% - 4px);
  flex: 1;
  color: white;
  width: 380px;
  .offer-label {
    color: white;
    font-size: 24px;
  }
`;
