import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { MainLayout } from "layouts";
import { SideTop, ScrollView, Button, RiseLoader, CheckBox, SearchRetailers } from "components";
import { times } from "lodash";
import { useRetailersContext } from "contexts";
import { useInput } from "hooks";
import { TableHeader, TableRow } from "./components";
import { getRetailerAutocompleteApi, getTiers } from "api";

export const RetailersPage = () => {
  const [sortCol, setSortCol] = useState("");
  const [sortType, setSortType] = useState(0);
  const [tiers, setTiers] = useState("");
  const search = useInput("");

  const {
    getRetailers,
    findRetailer,
    retailers = [],
    isLoading,
    isRefetching,
    totalCount = 0,
    totalPages,
    currentPage,
    loadMore,
    setFilter,
    is_active,
    queue_enabled
  } = useRetailersContext();

  const init = async () => {
    getRetailers();
    const res = await getTiers();
    if(res.success) {

        //console.log('get tiers', res.results.tiers);

       const tiersMapped = res.results.tiers.map((item) => ({
          ...item,
          title: item.ext_id,
          id: item.ext_id
        }))

        setTiers(tiersMapped);

    }else{
        //Raise Error
    }

  };

  useEffect(() => {
    init();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = async (params) => {
    if(params === "") {
      getRetailers();
    }else{
      findRetailer(params);
    }
  };

  const handleSelectRetailer = (params) => {
    setFilter({ 
      retailerId: params, 
      is_active: false,
      queue_enabled: false
     });
  };

  const handleIsActiveCheckbox = (poll) => {
    setFilter({ is_active: poll });
  };

  const handleQueueEnabledCheckbox = (poll) => {
    setFilter({ queue_enabled: poll });
  };

  const handleSort = (sortCol, sortType) => {
    setSortCol(sortCol);
    setSortType(sortType);
    setFilter({ sortBy: sortCol, sortDir: sortType === 1 ? "ASC" : "DESC" });
    getRetailers();
  };

  return (
    <MainLayout>
      <MainContent>
        <SideSection>
          <SideTop
            currentCount={retailers.length}
            totalCount={totalCount}
            placeholder="retailers"
            isLoading={isLoading}
          />
          <SearchRetailers
            isLoading={isLoading}
            func={getRetailerAutocompleteApi}
            onSearch={handleSearch}
            onSelect={handleSelectRetailer}
            {...search}
          />
          <CheckboxContainer>
            <CheckBox
              onChange={(e) => handleIsActiveCheckbox(e)}
              value={is_active}
            >
              Filter Active Retailers
            </CheckBox>
            <CheckBox
                onChange={(e) => handleQueueEnabledCheckbox(e)}
                value={queue_enabled}
              >
                Filter Queue Enabled Retailers
              </CheckBox>
          </CheckboxContainer>
        </SideSection>
        <TableSection>
          <TableHeader
            sortCol={sortCol}
            sortType={sortType}
            onSort={handleSort}
          />
          <TableContent> 
            {isLoading ? (
              times(7, String).map((index) => (
                <TableRow key={`${index}key`} isLoading />
              ))
            ) : retailers && retailers.length > 0 ? (
              retailers.map((item, index) => (
                <TableRow key={`${index}key`} data={item} tiers={tiers} />
              ))
            ) : (
              <div className="pt-4 mt-4 mx-auto text-center color-white">
                No retailers match this search request.
              </div>
            )}
            {!isLoading && isRefetching ? (
              <div className="d-flex">
                <RiseLoader className="my-4 mx-auto" />
              </div>
            ) : !isLoading && currentPage + 50 < totalPages * 50 ? (
              <div className="my-4 d-flex justify-content-center">
                <Button
                  size="small"
                  width="120px"
                  onClick={() =>
                    loadMore({
                      retailer_id: search.value,
                    })
                  }
                >
                  Load More
                </Button>
              </div>
            ) : null}
          </TableContent>
        </TableSection>
      </MainContent> 
    </MainLayout>
  );
};

const MainContent = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: auto;
  background: ${(props) => props.theme.palette.backgrounds};
`;

const SideSection = styled.div`
  width: 300px;
  border-right: 3px solid ${(props) => props.theme.palette.canvas};
  display: flex;
  flex-direction: column;
`;
const TableSection = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const TableContent = styled(ScrollView)`
  position: relative;
  transition: all 300ms ease;
  border: ${(props) =>
    props.isDragAccept || props.isDragActive
      ? `1px dashed ${props.theme.palette.primary}`
      : "1px solid transparent"};
`;

const CheckboxContainer = styled.div`
    padding:20px;
`;



