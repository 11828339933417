/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, Button, SearchableDropDown, SearchManufacturer } from "components";
import { useInput } from "hooks";
import { useQuery, useMutation } from "react-query";
import { getProductCategoriesApi, createBrandApi, getManufacturersAPI } from "api";
import { useAuthContext, useMainContext } from "contexts";

export const CreateBrand = ({
  originName,
  onCancel = () => {},
  onOk = () => {},
}) => {
  const name = useInput("");
  const category = useInput("");
  const manufacturer = useInput("");
  const { postPageEvents } = useMainContext();
  const { userInfo } = useAuthContext();
  const handleCreate = async () => {
    postPageEvents("click", "Create Brand");
    const res = await createMutation.mutateAsync({
      brand_name: name.value,
      sub_cat_i: category.value === "" ? null : category.value,
      mfg_id: manufacturer.value === "" ? null : manufacturer.value
    });
    onOk(res.payload.ext_id, res.payload.brand_name, res.payload);
  };
  const handleCancel = () => {
    postPageEvents("click", "Cancel Create Brand");
    onCancel();
  };
  const {
    data: { payload: subCatData },
  } = useQuery(["product-categories"], () => getProductCategoriesApi(), {
    initialData: { payload: [] },
    keepPreviousData: true,
  });

  const createMutation = useMutation((data) => createBrandApi(data));

  useEffect(() => {
    name.setValue(originName);
    return () => {};
  }, [originName]);

  return (
    <Container>
      <Header>CREATE BRAND</Header>
      <Content>
        <Text className="mb-2">
          Please make sure the brand does not already exist
        </Text>
        <Input2 label="New Brand Name" className="mt-2" {...name} />
        {userInfo.user?.is_superuser &&
        <SearchableDropDown
          className="mt-2"
          label="Select Sub Category (optional)"
          placeholder="-- Select a sub category --"
          data={subCatData.map((item) => ({
            id: item.ext_id,
            title: item.sub_cat_nm,
          }))}
          {...category}
        />}
        <SearchManufacturer
          className="mt-2"
          label="Select Manufacturer (optional)"
          placeholder="-- Select a manufacturer --"
          func={getManufacturersAPI}
          onChange={e => manufacturer.setValue(e)}
        />
        <div className="d-flex mt-4">
          <Button
            size="small"
            buttonTheme="dark"
            className="ml-auto"
            width="100px"
            onClick={handleCancel}
          >
            CANCEL
          </Button>
          <Button
            size="small"
            buttonTheme="dark"
            className="ml-2"
            width="150px"
            onClick={handleCreate}
            isLoading={createMutation.isLoading}
            disabled={!name.value}
          >
            CREATE BRAND
          </Button>
        </div>
      </Content>
    </Container>
  );
};
const Text = styled.div`
  font-size: 14px;
`;
const Container = styled.div`
  width: 380px;
  flex: 1;
  margin: 2px 2px;
  background: ${(props) => props.theme.palette.backgrounds};
  padding: 12px 8px;
`;

const Header = styled.div`
  text-transform: uppercase;
  color: white;
  font-size: ${(props) => props.theme.font.size.m};
  border-bottom: 1px solid ${(props) => props.theme.palette.secondary};
  position: relative;

  svg {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    cursor: pointer;

    &:hover {
      opacity: 0.7;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  flex: 1;
  max-height: calc(100% - 12px);
  color: white;
`;
