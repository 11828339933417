/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { Modal, Button, Link, ProductList, OfferList } from "components";
import { dupeOffer, dupeProduct, getAdBlockById, getCircularById, getImage, importOffers, importProducts, setDupe } from "api";
import styled from "styled-components";
import { useLocation } from "@reach/router";
import { useMutation, useQuery } from "react-query";
import { format } from "date-fns";



export const ImageMatchingModal = ({
  open,
  currentAdBlockData = '',
  circularName = '',
  dupe_block = '',
  onClose,
  navigateNext = () => { },
  view = 'offer',
  startTime = '',
  sizeData = [],
  onImport = () => {},
  onError = () => {}
}) => {
  const location = useLocation();
  const [confirmDupe, setConfirmDupe] = useState(false);
  const [similarCircularName, setSimilarCircularName] = useState('');
  const [productData, setProductData] = useState([]);
  const [offersData, setOffersData] = useState([]);

  const onLoad = async (data) => {
    const result = await getCircularById(data.circular_uuid);
    if (result.success) {
      setSimilarCircularName(
        result.payload.circ_subtype +
        "_" +
        result.payload.retailer.name +
        "_" +
        format(
          new Date(
            result.payload.circ_week_dt.replace(/-/g, "/").replace(/T.+/, "")
          ),
          "MM/dd/yyyy"
        ) +
        `_${result.payload.ext_id}` +
        `_${result.payload.circ_version ? result.payload.circ_version : 0}`
      );
    } else {
      //TODO: set error message about no circular matching
    }
    setProductData(data.products);
    setOffersData(data.offers);
  }

  const { data: { payload: currentImage } } = useQuery(
    ['currentImage', currentAdBlockData?.filename],
    async () => getImage(currentAdBlockData.filename),
    {
      enabled: currentAdBlockData?.filename ? true : false,
      initialData: { payload: [] },
    })

  const { data: { payload: similarAdBlockData } } = useQuery(
    ['similarAdBlockData', dupe_block],
    async () => getAdBlockById(dupe_block),
    {
      enabled: dupe_block ? true : false,
      initialData: { payload: [] },
      onSuccess: async (data) => { await onLoad(data.payload); }
    })

  const { data: { payload: similarImage } } = useQuery(
    ['similarImage', similarAdBlockData?.filename],
    async () => getImage(similarAdBlockData?.filename),
    {
      enabled: similarAdBlockData?.filename ? true : false,
      initialData: { payload: [] },
    }
  )

  const handleImportProduct = useMutation({
    mutationFn: (data) => importProducts(data.adblockId, data.parentId, data.startTime),
    onSuccess: (data) => {
      const products = data.payload
      console.log("pp", products, data)
      onClose();
      onImport(products);
    }
  });

  const handleDupeProduct = useMutation({
    mutationFn: (data) => dupeProduct(data.adblockId, data.parentId, data.startTime),
    onSuccess: () => {
      onClose();
      navigateNext()
    },
    onError: (error) => {
      onError(error);
    }
  })

  const handleDupeOffer = useMutation({
    mutationFn: (data) => dupeOffer(data.adblockId, data.parentId, data.startTime),
    onSuccess: () => {
      onClose();
      navigateNext()
    },
    onError: (error) => {
      onError(error);
    }
  })

  const handleImportOffer = useMutation({
    mutationFn: (data) => importOffers(data.adblockId, data.parentId, data.dupe, data.startTime),
    onSuccess: (data) => {
      onClose();
      onImport(data);
    }
  });

  const handleSetCompleteDuplicate = useMutation({
    mutationFn: (data) => setDupe(data.adblockId, data.parentId, startTime),
    onSuccess: () => {
      onClose()
      navigateNext()
    },
  })

  return (
    <Modal open={open} onClose={onClose} size="large" title={"Similar Block Identification"}>
      {(currentImage.isLoading || similarImage.isLoading) ? <Content>
        Loading
      </Content> :
        <>
          <Content>
            <div>
              <div>Matched Block</div>
              <div>{similarCircularName}</div>
              <Link target="_blank" href={
                location.origin
                + (
                  location.pathname.endsWith("/products/") ? "/product/" :
                    location.pathname.endsWith("/offers/") ? "/offer/" :
                      location.pathname.substring(0, location.pathname.indexOf("/", 1) + 1)
                ) + currentAdBlockData?.similar_circular_uuid
                + "/" + currentAdBlockData?.similar_page_uuid
                + "/" + currentAdBlockData?.similar_adblock_uuid
              }
              >
                <AdBlockImg
                  src={`data:image/jpeg;base64,${similarImage}`}
                  alt="adblock"
                  className="hover-ext"
                />
              </Link>
            </div>
            <div>
              <div>Current Block</div>
              <div>{circularName}</div>
              <AdBlockImg
                src={`data:image/jpeg;base64,${currentImage}`}
                alt="adblock"
              />
            </div>
          </Content>
          {view === 'product' ?
            <div>
              Products in Matched Block
              <ProductList
                productsData={productData}
                width="small"
                displayOnly={true}
                sizeData={sizeData}
              />
            </div> : <div>
              Offers in Matched Block
              <OfferList
                offersData={offersData}
                width="small"
                displayOnly={true}
                sizeData={sizeData}
              />
            </div>
          }
          <div className="d-flex mt-4" style={{
            justifyContent: 'center'
          }}>
            {view === 'product' ?
              <>
                <Button
                  size="small"
                  className="ml-2"
                  buttonTheme="dark"
                  width="fit-content"
                  type="button"
                  disabled={handleImportOffer.isLoading || handleImportProduct.isLoading || handleSetCompleteDuplicate.isLoading || !similarAdBlockData.products_approved}
                  onClick={() => handleImportProduct.mutate({
                    adblockId: currentAdBlockData.uuid,
                    parentId: similarAdBlockData.uuid,
                    startTime: startTime
                  })}
                >
                  Import Products (EM)
                </Button>
                <Button
                  size="small"
                  className="ml-2"
                  buttonTheme="dark"
                  width="fit-content"
                  type="button"
                  disabled={handleImportOffer.isLoading || handleImportProduct.isLoading || handleSetCompleteDuplicate.isLoading  || !similarAdBlockData.products_approved}
                  onClick={() => handleDupeProduct.mutate({
                    adblockId: currentAdBlockData.uuid,
                    parentId: similarAdBlockData.uuid,
                    startTime: startTime
                  })}
                >
                  Duplicate Products (IM)
                </Button>
              </> :
              <>
                <Button
                  size="small"
                  className="ml-2"
                  buttonTheme="dark"
                  width="fit-content"
                  type="button"
                  disabled={handleImportOffer.isLoading || handleImportProduct.isLoading || handleSetCompleteDuplicate.isLoading || !similarAdBlockData.offers_approved}
                  onClick={() => handleImportOffer.mutate({
                    adblockId: currentAdBlockData.uuid,
                    parentId: similarAdBlockData.uuid,
                    startTime: startTime
                  })}
                >
                  Import Offers (EM)
                </Button>
                <Button
                  size="small"
                  buttonTheme="dark"
                  className="ml-2"
                  width="fit-content"
                  type="button"
                  disabled={handleImportOffer.isLoading || handleImportProduct.isLoading || handleSetCompleteDuplicate.isLoading  || !similarAdBlockData.offers_approved}
                  onClick={() => handleDupeOffer.mutate({
                    adblockId: currentAdBlockData.uuid,
                    parentId: similarAdBlockData.uuid,
                    startTime: startTime
                  })}
                >
                  Duplicate Offers (IM)
                </Button>
              </>}
            <Button
              size="small"
              buttonTheme="green"
              width="fit-content"
              className="ml-2"
              type="button"
              disabled={handleImportOffer.isLoading || handleImportProduct.isLoading || handleSetCompleteDuplicate.isLoading}
              onClick={() => {
                if (confirmDupe) {
                  handleSetCompleteDuplicate.mutate({
                    adblockId: currentAdBlockData.uuid,
                    parentId: similarAdBlockData.uuid
                  })
                } else {
                  setConfirmDupe(true);
                }
              }}
            >
              {!confirmDupe ? 'Complete Duplicate (CM)' : 'Confirm?'}
            </Button>
          </div>
        </>
      }
    </Modal>
  );
};

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  div {
    margin: 10px;
    text-align: center;
  }
  .hover-ext{
    &:hover{
      opacity: 0.4;
    }
  }
`

const AdBlockImg = styled.img`
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-height: 50vh;
  user-select: none;
  .hover-ext{
    &:hover{
      opacity: 0.2;
      border: 2px solid red;
    }
  }
`;
