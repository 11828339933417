export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const SET_USER_MODE = "SET_USER_MODE";
export const GET_USER_INFO = "GET_USER_INFO";
export const GET_APP_INFO = "GET_APP_INFO";

export const SYNC_REQUEST = "SYNC_REQUEST";

export const QUERY_POSTS = "QUERY_POSTS";
export const QUERY_INIT = "QUERY_INIT";
export const SET_QUERY = "SET_QUERY";
export const SET_QUERY_PAGE = "SET_QUERY_PAGE";

export const GET_POST = "GET_POST";
export const INIT_POST = "INIT_POST";
export const ADD_POST = "ADD_POST";
export const POST_HOME = "POST_HOME";
export const REMOVE_POST = "REMOVE_POST";
export const DELETE_POST = "DELETE_POST";
export const SET_POST_FETCHING = "SET_POST_FETCHING";
export const FETCH_MORE_POSTS = "FETCH_MORE_POSTS";

export const SET_LOADING = "SET_LOADING";
export const SET_REFETCHING = "SET_REFETCHING";
export const SET_ERROR = "SET_ERROR";

export const GET_JOBS = "GET_JOBS";
export const GET_MORE_JOBS = "GET_MORE_JOBS";
export const INIT_JOBS = "INIT_JOBS";
export const SET_JOBS_SEARCH = "SET_JOBS_SEARCH";
export const SET_JOBS_FILTER = "SET_JOBS_FILTER";

export const GET_CIRCULARS = "GET_CIRCULARS";
export const GET_CIRCULARS_BY_ID = "GET_CIRCULARS_BY_ID";
export const GET_MORE_CIRCULARS = "GET_MORE_CIRCULARS";
export const INIT_CIRCULARS = "INIT_CIRCULARS";
export const SET_CIRCULARS_SEARCH = "SET_CIRCULARS_SEARCH";
export const SET_CIRCULARS_FILTER = "SET_CIRCULARS_FILTER";

export const GET_USERS = "GET_USERS";
export const GET_MORE_USERS = "GET_MORE_USERS";
export const INIT_USERS = "INIT_USERS";
export const SET_USERS_SEARCH = "SET_USERS_SEARCH";
export const SET_USERS_FILTER = "SET_USERS_FILTER";

export const GET_SCHEDULERS = "GET_SCHEDULERS";
export const GET_MORE_SCHEDULERS = "GET_MORE_SCHEDULERS";
export const INIT_SCHEDULERS = "INIT_SCHEDULERS";
export const SET_SCHEDULERS_SEARCH = "SET_SCHEDULERS_SEARCH";
export const SET_SCHEDULERS_FILTER = "SET_SCHEDULERS_FILTER";
export const GET_SCHEDULER_ID = "GET_SCHEDULER_ID";
export const DELETE_SCHEDULER_ID = "DELETE_SCHEDULER_ID";

export const GET_RETAILERS = "GET_RETAILERS";
export const GET_SINGLE_RETAILER = "GET_SINGLE_RETAILER";
export const GET_MORE_RETAILERS = "GET_MORE_RETAILERS";
export const INIT_RETAILERS = "INIT_RETAILERS";
export const SET_RETAILERS_SEARCH = "SET_RETAILERS_SEARCH";
export const SET_RETAILERS_FILTER = "SET_RETAILERS_FILTER";


export const GET_MATCH_FILTERS = "GET_MATCH_FILTERS";
export const DELETE_MATCH_FILTER = "DELETE_MATCH_FILTER";
export const GET_MORE_MATCH_FILTERS = "GET_MORE_MATCH_FILTERS";
export const INIT_MATCH_FILTERS = "INIT_MATCH_FILTERS";
export const SET_MATCH_FILTERS_SEARCH = "SET_MATCH_FILTERS_SEARCH";
export const SET_MATCH_FILTERS_FILTER = "SET_MATCH_FILTERS_FILTER";

export const GET_PILOT_SETS = "GET_PILOT_SETS";
export const GET_MORE_PILOT_SETS = "GET_MORE_PILOT_SETS";
export const INIT_PILOT_SETS = "INIT_PILOT_SETS";
export const SET_PILOT_SETS_SEARCH = "SET_PILOT_SETS_SEARCH";
export const SET_PILOT_SETS_FILTER = "SET_PILOT_SETS_FILTER";
export const DELETE_PILOT_SET = "DELETE_PILOT_SET";

export const GET_TEMPLATES = "GET_TEMPLATES";
export const GET_MORE_TEMPLATES = "GET_MORE_TEMPLATES";
export const INIT_TEMPLATES = "INIT_TEMPLATES";
export const GET_TEMPLATE_ID = "GET_TEMPLATE_ID";
export const GET_TEMPLATE_RETAILER = "GET_TEMPLATE_RETAILER";
export const SET_TEMPLATES_SEARCH = "SET_TEMPLATES_SEARCH";
export const SET_TEMPLATES_FILTER = "SET_TEMPLATES_FILTER";

export const GET_SCRAPE_TEMPLATES = "GET_SCRAPE_TEMPLATES";
export const GET_MORE_SCRAPE_TEMPLATES = "GET_MORE_SCRAPE_TEMPLATES";
export const INIT_SCRAPE_TEMPLATES = "INIT_SCRAPE_TEMPLATES";
export const GET_SCRAPE_TEMPLATE_ID = "GET_SCRAPE_TEMPLATE_ID";
export const GET_SCRAPE_TEMPLATE_RETAILER = "GET_SCRAPE_TEMPLATE_RETAILER";
export const SET_SCRAPE_TEMPLATES_SEARCH = "SET_SCRAPE_TEMPLATES_SEARCH";
export const SET_SCRAPE_TEMPLATES_FILTER = "SET_SCRAPE_TEMPLATES_FILTER";

export const GET_RETAILERSROLLUP = "GET_RETAILERSROLLUP";
export const INIT_RETAILERSROLLUP = "INIT_RETAILERSROLLUP";
export const SET_RETAILERSROLLUP_SEARCH = "SET_RETAILERSROLLUP_SEARCH";
export const SET_RETAILERSROLLUP_FILTER = "SET_RETAILERSROLLUP_FILTER";

export const SET_POST_TOOLBAR_STATE = "SET_POST_TOOLBAR_STATE";
