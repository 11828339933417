/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import styled from "styled-components";
import { Input2, DropDown2, ToggleButton } from "components";
import { useInput } from "hooks";
export const IncentiveEnterPurchaseSavingsTextBuilder = ({
  className,
  value = "",
  onChange = () => {},
}) => {
  const isUpTo = useInput(false);
  const saveVal = useInput("");
  const saveType = useInput("$");
  const offType = useInput("");
  const purchaseVal = useInput("");
  const purchaseType = useInput("Unit");
  const suffix = useInput("");
  const typeData = [
    { id: "$", title: "$" },
    { id: "%", title: "%" },
  ];
  const typeData2 = [
    { id: "$", title: "$" },
    { id: "Unit", title: "Unit" },
  ];
  const typeData3 = [
    { id: "entire purchase", title: "Entire Purchase" },
    { id: "any one item", title: "Any one item" },
  ];
  const withPurchaseRegex = /(w\/purchase\/\s*)+/g; // /(w\/purchase\/\s*)+/g
  const withPurchaseUnitRegex = /(w\/purchase\/\s*\.\d*\s*)+/g; // /(w\/purchase\/\s*)+/g

  const handleUpdateValue = (ut, sv, st, ot, pv, pt, suf) => {
    const withPurchaseStr = `${pv ? `w/purchase/${pt === "$" ? `${pt}${pv}` : pv}` : ''}`
    let str = `Save${ut ? " Up To" : ""} ${st === "$" ? `${st}${sv}` : `${sv}${st}`
      } off${ot ? ` ${ot}` : ""} ${withPurchaseStr} ${suf ? ` ${suf}` : ""
      }`
    if (purchaseVal.value)
      str = pt === "$" ? str : str.replace(withPurchaseUnitRegex, withPurchaseStr);
    else
      str = str.replace(withPurchaseRegex, 'w/purchase/');
    onChange(str);
  };

  useEffect(() => {
    if (value.startsWith("Save")) {
      let main = value.slice(4).trimStart();
      if (purchaseVal.value)
        main = main.replace(withPurchaseRegex, 'w/purchase/');
      // else
      // main = main.replace(withPurchaseRegex, 'w/purchase/');
      // main = main.replace(withPurchaseRegex, 'w/purchase/');

      if (main.startsWith("Up To")) {
        isUpTo.setValue(true);
        main = main.slice(5).trimStart();
      } else {
        isUpTo.setValue(false);
      }
      const offIndex = main.indexOf("off");
      if (offIndex >= 0) {
        const st = main.slice(0, offIndex).trim();
        if (st.startsWith("$")) {
          saveType.setValue("$");
          saveVal.setValue(st.slice(1));
        } else if (st.endsWith("%")) {
          saveType.setValue("%");
          saveVal.setValue(st.slice(0, -1));
        }
        main = main.slice(offIndex + 3).trimStart();
      }
      const purchaseIndex = main.indexOf("w/purchase/");
      if (purchaseIndex > 0) {
        offType.setValue(main.slice(0, purchaseIndex).trim());
        main = main.slice(purchaseIndex + 11);
        const sufIndex = main.indexOf(" ");
        let vt = "";
        if (sufIndex >= 0) {
          vt = main.slice(0, sufIndex);
          // suffix.setValue(main.slice(sufIndex + 1));
        } else {
          vt = main;
          // suffix.setValue("");
        }
        if (vt.startsWith("$")) {
          purchaseType.setValue("$");
        //   purchaseVal.setValue(vt.slice(1).trim());
        } else {
          purchaseType.setValue("Unit");
        //   purchaseVal.setValue(vt.trim());
        }
      } else {
        if (main.startsWith("entire purchase")) {
          offType.setValue("entire purchase");
          main = main.slice(16);
        } else if (main.startsWith("any one item")) {
          offType.setValue("any one item");
          main = main.slice(13);
        }
        suffix.setValue(main);
      }
      value = value.replace(withPurchaseRegex, 'w/purchase/');
    }
    return () => {};
  }, [value]);

  return (
    <div className={`${className} mt-1 py-2`}>
      <Title>Incentive Text Builder</Title>
      <Container className="mt-2">
        <div className="mt-auto mb-2">Save</div>
        <ToggleButton
          className="mt-auto mb-2 ml-2"
          label="Up To"
          value={isUpTo.value}
          onChange={(e) => {
            isUpTo.setValue(e);
            handleUpdateValue(
              e,
              saveVal.value,
              saveType.value,
              offType.value,
              purchaseVal.value,
              purchaseType.value,
              suffix.value
            );
          }}
        />
        <div style={{ width: 100, marginLeft: 20 }}>
          <Input2
            label="Value"
            value={saveVal.value}
            type="float"
            onChange={(e) => {
              saveVal.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                e,
                saveType.value,
                offType.value,
                purchaseVal.value,
                purchaseType.value,
                suffix.value
              );
            }}
          />
        </div>
        <div style={{ width: 60, marginLeft: 20 }}>
          <DropDown2
            label="Type"
            data={typeData}
            width={70}
            value={saveType.value}
            onChange={(e) => {
              saveType.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                saveVal.value,
                e,
                offType.value,
                purchaseVal.value,
                purchaseType.value,
                suffix.value
              );
            }}
          />
        </div>
      </Container>
      <Container>
        <div className="mt-auto mb-1">off</div>
        <div style={{ width: 270, marginLeft: 10 }}>
          <DropDown2
            label=""
            data={typeData3}
            width={70}
            value={offType.value}
            onChange={(e) => {
              offType.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                saveVal.value,
                saveType.value,
                e,
                purchaseVal.value,
                purchaseType.value,
                suffix.value
              );
            }}
          />
        </div>
      </Container>
      <Container className="mt-1">
        <div className="mt-auto mb-2">w/purchase</div>
        <div style={{ width: 60, marginLeft: 10 }}>
          <Input2
            label="Value"
            value={purchaseVal.value}
            type="float"
            onChange={(e) => {
              purchaseVal.setValue(e);
              handleUpdateValue(e);
              handleUpdateValue(
                isUpTo.value,
                saveVal.value,
                saveType.value,
                offType.value,
                e,
                purchaseType.value,
                suffix.value
              );
            }}
          />
        </div>
        <div style={{ width: 70, marginLeft: 10 }}>
          <DropDown2
            label="Type"
            data={typeData2}
            width={70}
            value={purchaseType.value}
            onChange={(e) => {
              // purchaseType.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                saveVal.value,
                saveType.value,
                offType.value,
                purchaseVal.value,
                e,
                suffix.value
              );
            }}
          />
        </div>
        <div style={{ width: 80, marginLeft: 10 }}>
          <Input2
            label="Suffix"
            value={suffix.value}
            onChange={(e) => {
              suffix.setValue(e);
              handleUpdateValue(
                isUpTo.value,
                saveVal.value,
                saveType.value,
                offType.value,
                purchaseVal.value,
                purchaseType.value,
                e
              );
            }}
          />
        </div>
      </Container>
    </div>
  );
};

const Title = styled.div`
  color: #66c0e1;
  font-size: 16px;
`;
const Container = styled.div`
  background: ${(props) => props.theme.palette.items};
  padding: 8px;
  display: flex;
`;
