/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useState, useEffect } from "react";
import classNames from "classnames";
import { useDebounce } from "use-debounce";
import { useOutsideClick } from "hooks";
import styled from "styled-components";
import { useQuery } from "react-query";
import { getRetailerByIdApi } from "api";
import { AiOutlineClose } from "react-icons/ai";
import { FaSearch } from "react-icons/fa";
import ClipLoader from "react-spinners/ClipLoader";
import "./style.scss";

export const SearchRetailers= ({
  value,
  onChange,
  label,
  className,
  backgroundColor = "#eee",
  placeholder = "Search by retailer name",
  width,
  idField = "id",
  displayValue = "",
  searchButton = true,
  borderBottom = true,
  func = () => {},
  onSelect = () => {},
  onSearch = () => {},
  onClick = () => {},
}) => {
  const menuRef = useRef(null);
  const [search, setSearch] = useState("");
  const [showResults, setShowResults] = useState(false);
  const [debouncedValue] = useDebounce(search, 500);
  const [innerValue, setInnerValue] = useState("");

  useOutsideClick(menuRef, clickOutsideMenu);
  function clickOutsideMenu() {
    setShowResults(false);
  }
  const handleChange = (params) => {
    if (params !== value) {
      setSearch("");
      onChange(params[idField]);
      onSelect(params[idField]);
      setInnerValue(params.name);
      // onSearch(params[idField]);
    }
    setShowResults(false);
  };

  const { data, isLoading, isFetching } = useQuery(
    [debouncedValue],
    () => func(debouncedValue),
    {
      keepPreviousData: true,
      enabled: debouncedValue.length > 0,
    }
  );

  const [focused, setFocused] = useState(false);

  const handleBlur = (event) => {
    setFocused(false);
  };

  const handleClear = () => {
    onChange("");
    setSearch("");
    onSelect("");
    setInnerValue("");
    // onSearch("");
  };

  useEffect(() => {
    if (debouncedValue) {
      setShowResults(true);
    } else {
      setShowResults(false);
    }
    return () => {};
  }, [debouncedValue]);

  useEffect(() => {
    if (displayValue) {
      setInnerValue(displayValue);
    }
    return () => {};
  }, [displayValue]);

  useEffect(() => {
    if (value) {
      getInnerValue(value);
    } else {
      setInnerValue("");
    }
    return () => {};
  }, [value]);
  const getInnerValue = async (id) => {
    const res = await getRetailerByIdApi(id);
    if (res.success) {
      setInnerValue(res.results.name);
    }
  };

  const handleSearch = () => {
    onSearch(value);
  };

  return ( 

    <div className={borderBottom ?  'd-flex search-container': ""} onClick={onClick}>
      <div className="search-circular" style={{ width }}>
        <div className="menu-button" style={{ width }}>
          {innerValue ? (
            <>
              <span className="mr-auto">{innerValue}</span>
              <AiOutlineClose
                color="white"
                className="menu-button__drop-down"
                alt="clear"
                onClick={() => handleClear()}
              />
            </>
          ) : (
            <div className="d-flex w-100">
              <StyledInput
                type="text"
                aria-labelledby={"auto complete"}
                onFocus={() => setFocused(true)}
                onBlur={handleBlur}
                aria-describedby={"auto complete"}
                value={search}
                placeholder={placeholder}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
              />

              {/* <AiFillCaretDown
                color="white"
                className="menu-button__drop-down"
                alt="drop-down"
              /> */}
            </div>
          )}

          <Underline focused={focused} />
        </div>
        {showResults && (
          <div ref={menuRef} className="menu-dropdown">
            {isLoading || isFetching ? (
              <div className="d-flex justify-content-center py-4">
                <ClipLoader size={32} color="#006280" />
              </div>
            ) : data?.results && data?.results.length > 0 ? (
              data.results.map((item, index) => {
                return (
                  <div
                    className={classNames(
                      "menu-item",
                      item.name === value ? "active" : ""
                    )}
                    key={`key${index}`}
                    onClick={() => handleChange(item)}
                    style={{ width }}
                  >
                    {item.name}
                  </div>
                );
              })
            ) : (
              <div className="my-3 d-flex justify-content-center">
                No results
              </div>
            )}
          </div>
        )}
      </div>
      { searchButton ? 
        <FaSearch className={"search-button"} onClick={handleSearch} />
      :null}
    </div>
  );
};

const StyledInput = styled.input`
  background: transparent;
  color: white;
  border: none;
  height: 32px;
  line-height: 32px;
  width: calc(100% - 24px);
  font-size: 14px;
  outline: none;
  &:focus,
  &:hover {
    border: none;
    outline: none;
  }
`;

const Underline = styled.div`
  background: ${(props) => props.theme.palette.primary};
  width: calc(100% - 24px);
  height: 2px;
  position: absolute;
  bottom: 0;
  transform-origin: left;
  transition: transform 500ms ease;
  transform: ${(props) => (props.focused ? "" : "scale3d(0, 1, 1)")};
`;
