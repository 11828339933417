import React from "react";
import theme from "theme";
import { ThemeProvider } from "styled-components";
import { Router } from "@reach/router";
import {
  LoginPage,
  CircularsPage,
  RegisterPage,
  ForgotPage,
  NotFoundPage,
  ResetPage,
  CircularDetailPage,
  HomePage,
  SectionPage,
  AdBlockPage,
  AdBlocksPage,
  ObjectPage,
  OfferPage,
  OffersPage,
  ProductPage,
  ProductsPage,
  UsersPage,
  RetailersPage,
  RetailersRollupPage,
  TiersPage
} from "pages";
import { PrivateRoute, PublicRoute, ErrorPage } from "components";
import {
  AuthProvider,
  CircularProvider,
  MainProvider,
  UsersProvider,
  AdblockHistoryProvider,
  RetailersProvider,
  RetailersRollupProvider
} from "contexts";
import { CookiesProvider } from "react-cookie";
import ReactTooltip from "react-tooltip";
import { ErrorBoundary } from "react-error-boundary";
import { ConfigProvider, theme as AntdTheme } from "antd";
// import * as Sentry from "@sentry/react";
// import { BrowserTracing } from "@sentry/tracing";
import "theme/all.scss";
import "bootstrap/dist/css/bootstrap.min.css";
// Sentry.init({
//   dsn: "https://d75cbc2e8acb4e689ba788ddd13ddd79@o1009700.ingest.sentry.io/6253009",
//   integrations: [new BrowserTracing()],

//   // Set tracesSampleRate to 1.0 to capture 100%
//   // of transactions for performance monitoring.
//   // We recommend adjusting this value in production
//   tracesSampleRate: 1.0,
//   initialScope: scope => {
//     scope.setTags({ a: "addison-dev" });
//     return scope;
//   },
// });

function App() {
  function ErrorFallback({ error, resetErrorBoundary }) {
    return (
      <ErrorPage
        role="alert"
        error={error}
        resetErrorBoundary={resetErrorBoundary}
      />
    );
  }

  return (
    <ConfigProvider
      theme={{
        algorithm: AntdTheme.darkAlgorithm,
        "components": {
          "Badge": {
            "textFontSize": 14,
            "textFontWeight": "bold"
          }
        }
      }}
    >
      <div className="App">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <CookiesProvider>
            <ThemeProvider theme={theme}>
              <AuthProvider>
                <MainProvider>
                  <UsersProvider>
                    <RetailersProvider>
                      <CircularProvider>
                        <AdblockHistoryProvider>
                          <RetailersRollupProvider>
                            <Router>
                              <NotFoundPage default />

                              <PublicRoute as={LoginPage} path="/" />
                              <PublicRoute as={NotFoundPage} path="/404" />
                              <PublicRoute as={LoginPage} path="/login" />
                              <PublicRoute as={RegisterPage} path="/register" />
                              <PublicRoute as={ResetPage} path="/reset-password" />
                              <PublicRoute as={ForgotPage} path="/forgot-password" />
                              <PrivateRoute as={HomePage} path="/home" />
                              <PrivateRoute as={CircularsPage} path="/circular" />
                              <PrivateRoute as={UsersPage} path="/users" />
                              <PrivateRoute as={RetailersPage} path="/retailers" />
                              <PrivateRoute as={RetailersRollupPage} path="/retailers-rollup" />
                              <PrivateRoute
                                as={CircularDetailPage}
                                path="/circular/:id/:page/*adblock"
                              />
                              <PrivateRoute as={SectionPage} path="/section" />
                              <PrivateRoute as={ObjectPage} path="/object" />
                              <PrivateRoute
                                as={AdBlockPage}
                                path="/adblock/:circular/:page"
                              />
                              <PrivateRoute as={AdBlocksPage} path="/adblocks/" />
                              <PrivateRoute
                                as={OfferPage}
                                path="/offer/:circular/:page/:adblock"
                              />
                              <PrivateRoute as={OffersPage} path="/offers/" />
                              <PrivateRoute
                                as={ProductPage}
                                path="/product/:circular/:page/:adblock"
                              />
                              <PrivateRoute as={ProductsPage} path="/products/" />
                              <PrivateRoute as={TiersPage} path="/tiers/" />
                            </Router>
                            <ReactTooltip
                              id="player-tools"
                              multiline
                              place="bottom"
                              effect="solid"
                              className="player-tools"
                            />
                          </RetailersRollupProvider>
                        </AdblockHistoryProvider>
                      </CircularProvider>
                    </RetailersProvider>
                  </UsersProvider>
                </MainProvider>
              </AuthProvider>
            </ThemeProvider>
          </CookiesProvider>
        </ErrorBoundary>
      </div>
    </ConfigProvider>
  );
}

export default App;
